import Vue from 'vue';
import Debug from 'debug';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import filters from '@/filters'

const debug = Debug('dwa::main');

Vue.config.productionTip = false;

// register filters
Object.keys(filters).forEach(key => { Vue.filter(key, filters[key]) }) 

new Vue({
  router,
  store,
  vuetify,
  created() {
    store.dispatch('loadPolkadot')
      .then(() => debug('Store: loadPolkadot action done.'));
    store.dispatch('loadKusama')
      .then(() => debug('Store: loadKusama action done.'));
  },
  render: (h) => h(App),
}).$mount('#app');

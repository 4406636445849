
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { RootState } from '@/store';
import CandidatesList from '@/components/Home/CandidatesList.vue';

@Component({
  components: {
    CandidatesList,
  },
})
export default class KusamaDashboard extends Vue {
  @State('kCandidates') kCandidates!: RootState['kCandidates']
}


import 'reflect-metadata';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ContactModal from '@/components/ContactModal.vue';

@Component({
  components: {
    ContactModal,
  },
  computed: {
    ...mapState(['loading']),
  },
})
export default class HeaderBar extends Vue {}

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import PolkadotDashboard from '../views/PolkadotDashboard.vue';
import KusamaDashboard from '../views/KusamaDashboard.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'PolkadotDashboard',
    component: PolkadotDashboard,
  },
  {
    path: '/polkadot',
    name: 'PolkadotDashboard',
    component: PolkadotDashboard,
  },
  {
    path: '/kusama',
    name: 'KusamaDashboard',
    component: KusamaDashboard,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

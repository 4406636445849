
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { RootState } from '@/store';
import _ from 'lodash';

@Component({
  components: {},
})
export default class CandidatesList extends Vue {
  @State('loading') loading!: RootState['loading'];

  @Prop({ required: true }) readonly chain!: string;

  @Prop({ required: true }) readonly data!: Polkadot.Api;

  private search: string = '';

  private headers = [
    { text: 'Identity', align: 'center', value: 'identity' },
    { text: 'Stash', align: 'center', value: 'stash' },
    { text: 'Status', align: 'center', value: 'status' },
  ]

  private headersBackups = [
    { text: 'Identity', align: 'center', value: 'identity' },
    { text: 'Stash', align: 'center', value: 'stash' },
  ]

  public filterOnlyCapsText(value: string, search: string, item: Polkadot.Selected): boolean {
    return _.includes(item.identity, search);
  }
}

import _ from 'lodash';
import { DateTime } from 'luxon';

export default ({
  humanizeData(value: number | string): string | null {
    const valNum = _.toNumber(value);
    if (valNum <= 0) return _.toString(value);
    const date = DateTime.fromMillis(valNum);
    return date.toRelative();
  },
  digitFloat(value: number | string, digits: number, prefix?: string): number | string {
    if (_.toString(value).length > digits + 2) {
      return (prefix || '') + _.toNumber(value).toFixed(digits);
    }
    return value;
  },
  percentageOver(value: number | string, max: number | string, digits = 2): number | string {
    const v = _.toNumber(value);
    if(v === 0) return v;
    const m = _.toNumber(max);
    return ((v * 100) / m).toFixed(digits);
  },
  prettyBytes(num: string | number) {
    num = _.toNumber(num)
    if (isNaN(num)) {
      throw new TypeError('Expected a number');
    }
  
    const neg = num < 0;
    const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
    if (neg) {
      num = -num;
    }
  
    if (num < 1) {
      return (neg ? '-' : '') + num + ' B';
    }
  
    const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
    num = _.toNumber((num / Math.pow(1000, exponent)).toFixed(2));
    const unit = units[exponent];
  
    return (neg ? '-' : '') + num + ' ' + unit;
  }
// eslint-disable-next-line
} as { [key: string]: any })